/* @import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,400;0,500;0,600;0,700;1,400;1,500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,400;0,500;0,600;0,700;1,400;1,500&family=Lora:ital@0;1&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
  /* cursor: none; */
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
.cursor {
  background-color: #111;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 999;
}
body {
  height: 100vh;
  margin: 0;
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1a1818;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 3.15rem;
}
h2, .h2 {
  font-size: 2.25rem;
}
h3, .h3 {
  font-size: 2rem;
}
h4, .h4 {
  font-size: 1.25rem;
}
h5, .h5 {
  font-size: 1.15rem;
}
h6, .h6 {
  font-size: 0.95rem;
}
.fs-lg {
  font-size: 2.6rem;
}
.fs-xl {
  font-size: 3.75rem;
}
.fs-xxl {
  font-size: 4.25rem;
}
.fs-md {
  font-size: 1.5rem;
}

.fs-sm {
  font-size: 1rem;
}
.fs-xs {
  font-size: 0.95rem;
}
/**/
.f-lora-i {
  font-family: 'Lora', serif;
  font-style: italic;
}
.f-lora {
  font-family: 'Lora', serif;
  font-weight: 400;
}
/**/
.fw-extralight {
  font-weight: 200;
}
.fw-regular {
  font-weight: 400;
}
.fw-semibold {
  font-weight: 500;
}
.fw-bold {
  font-weight: 600;
}
.font-italic {
  font-style: italic;
}
.text-justify {
  text-align: justify;
}
/**/
.ls-4 {
  letter-spacing: 5px;
}
/**/
.text-gold {
  color: #9F8C50;
}
.text-orange {
  color: #b36e4e;
}
.text-brown {
  color: #714023;
}
.text-blue {
  color: #51657e;
}
.text-pink {
  color: #b08684;
}
/**/
.bg-gold {
  background-image: url(./Images/gold-bg.jpg);
  background-size: cover;
  background-position: center center;
  color: #fff;
}
.bg-light-gold {
  background-image: url(./Images/light-gold-bg.jpg);
  background-size: cover;
  background-position: center center;
  color: #fff;
}
.outter-box {
  margin: 0 -80px 50px -80px;
  padding: 100px 80px;
}
.bg-brown {
  background-image: url(./Images/brown-bg.jpg);
  background-size: cover;
  background-position: center center;
  color: #fff;
}
.bg-orange {
  background-color: #b36e4e;
  color: #fff;
}
/**/
.mr-n2 {
  margin-right: -1.5rem;
}
/*  _ * _  */
.w-100 {
  width: 100%;
}
/*  _ * _  */
/* .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-sm-6 {
  flex: 0 0 50%;
  max-width: 50%;
} */

.row > * {
  /* flex-shrink: 0;
  width: 100%;
  max-width: 100%; 
  padding-right: 15px;
  padding-left: 15px;*/
}
/*  _ * _  */
.intro-video-wrap {
  
}
.intro-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  display: block;
}
.intro-skip {
  position: absolute;
  right: 0;
  bottom: 0;
}
.video-skip-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: #fff;
}
.video-skip-btn:hover {
  color: #fff;
}
.video-skip-btn::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  margin-top: 0.5em;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition: -webkit-transform 300ms ease-out;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
}
.video-skip-btn:hover::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/***/
.main-content {
  display: none;
}
.main-content-show {
  display: block;
}
/**/

.magazine-wrap {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.left-unit {
  max-width: 50%;
  flex: 0 0 50%;
  background-color: #5c2a2b;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.front-left-img {
  width: 500px;
  height: 644px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
.left-unit-mob {
  background-color: #5c2a2b;
  height: 100vh;
  padding: 50px 0;
  display: none;
  align-items: center;
  justify-content: center;
}
.left-unit-mob img {
  height: auto;
  max-height: 100%;
  max-width: 90%;
  width: auto;
}
.list-img {
  position: absolute;
  left: 50%;
  margin-left: -250px;
  top: 50%;
  margin-top: -319px;
  transition: all 0.5s ease;
}
.right-unit {
  max-width: 50%;
  flex: 0 0 50%;
  background-color: #f4efeb;
  padding: 30px 80px;
  overflow: auto;
  position: relative;
  z-index: 1;
}
.article-list {
  border-bottom: 1px solid #ded5cd;
  padding: 35px 0;
  display: block;
  text-decoration: none;
}
.article-list:last-child {
  border-bottom: none;
}
.article-list a {
  text-decoration: none;
  display: block;
}

.article-list h6 {
  font-size: 1rem;
  color: #686764;
  margin: 0 0 25px 0;
}
.article-list h4 {
  font-size: 1.625rem;
  color: #1a1818;
  margin: 0 0 10px 0;
}
.article-list h5 {
  font-size: 1.4rem;
  color: #92918f;
  font-weight: 400;
  margin: 0;
}

/* .article-list h4,
.article-list h5 {
  display: inline-block;
  background-image: linear-gradient(90deg,currentColor 0,currentColor);
  background-size: 0 2px;
  background-position: 0 95%;
  transition: background-size .25s cubic-bezier(.785,.135,.15,.86) 0s;
  background-repeat: no-repeat;
}

.article-list:hover h4,
.article-list:hover h5 {
  background-size: 100% 2px;
} */
/**/
.article-left {
  max-width: 50%;
  flex: 0 0 50%;
  height: 100%;
  /* background-image: url(Images/article-img1.jpg);
  background-position: center center;
  background-size: cover; */
}
.article-left img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.75s ease;
}
.article-left-mob {
  height: 100vh;
  padding: 0;
  margin-bottom: 30px;
  display: none;
  align-items: center;
  justify-content: center;
}
.article-left-mob img {
  object-fit: cover;
  height: 100vh;
  width: 100%;
}
.article-left img.active {
  opacity: 1;
}
.article-right {
  max-width: 50%;
  flex: 0 0 50%;
  background-color: #f4efeb;
  padding: 50px 80px 100px 80px;
  overflow: auto;
  position: relative;
  z-index: 1;
}

.article-right h5 {
  margin: 0 0 25px 0;
}
.article-right h6 {
  margin: 0 0 60px 0;
}
.short-divder {
  width: 50px;
  height: 2px;
  background-color: #1a1818;
  margin: 40px 0;
}
/***/
/* .article2 h1 {
  margin: 60px 0 20px 0;
}
.article2 h4 {
  margin: 0 0 60px 0;
} */
.article2-sec2 {
  margin-top: 120px;
}
.article2-sec2-top {
  background-image: url(Images/article2-img3.png);
  background-repeat: no-repeat;
  background-position: right top;
  padding-top: 80px;
  height: 287px;
  margin-bottom: 30px;
  background-size: 170px auto;
}
.article2-sec2 h6 {
  margin-bottom: 15px;
}
/**/
.article9-wrap .article-left img {
  object-position: left top;
  
}
.article9-wrap .article-right {
  background-color: #fff8e2;
}
/**/
.secondSec {
  position: relative;
}
.secondSec:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-image: url(Images/article2-img3.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/**/
/* .cursor {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%);
  border-radius: 50%;
  background: transparent;
  pointer-events: none;
  z-index: 111;
  border: 1px solid #000;
  transition: all 0.2s ease-out;
  animation: moveCursor1 .5s infinite alternate;
} */

.expand {
  background: transparent;
  animation: moveCursor2 .5s forwards;
  border: 1px solid #000;
}

@keyframes moveCursor1 {
  from {
      transform: scale(1);
  }

  to {
      transform: scale(.8);
  }
}

@keyframes moveCursor2 {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(2);
  }

  100% {
      transform: scale(1);
      opacity: 0;
  }
}
/********/
.hamburger-menu {
  text-align: right;
  margin-bottom: 40px;
}
@media (max-width:991px){
  .right-unit,
  .left-unit,
  .article-left,
  .article-right {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .left-unit, .article-left {
    display: none;
  }
  .right-unit, .article-right {
    padding: 0;
  }
  .left-unit-mob, .article-left-mob {
    display: flex;
  }
  .article-list, .article-mob-wrap {
    padding: 30px 40px;
  }
  .hamburger-menu {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
@media (max-width:767px){
  .article-list,
  .article-right,
  .article-mob-wrap {
    padding-left: 30px;
    padding-right: 30px;
  }
  .article-right {
    padding-left: 0;
    padding-right: 0;
  }
}


/******/
.fold{
  transition: all ease .5s;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 120px;
  height: 120px;
  background: none;
  outline: none;
  border: none;
}

/*
  paper fold corner
*/

.fold:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  /* border-color: rgba(255, 255, 255, 0.85) #5f7f72; */
  border-color: rgba(255, 255, 255, 0.85) #1a1818;
  transition: all ease 0.75s;
}

/*
  on li hover make paper fold larger
*/
.fold:hover:before {
  border-width: 0 0 100px 100px;
  border-color: rgba(255, 255, 255, 0.85) #1a1818;
}
.fold.active {
  width: 100%;
  height: 100%;
}
.fold.active:before {
  border-width: 0 0 2900px 2900px;
  border-color: rgba(255, 255, 255, 0.85) #1a1818;
}

/******/
.unfold{
  transition: all ease .5s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 200px;
  height: 200px;
}

/*
  paper fold corner
*/

.unfold:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #fff #34495E;
  transition: all ease 1.5s;
}

/*
  on li hover make paper fold larger
*/
/* .unfold:hover:before {
  border-width: 100px 100px 0 0;
  border-color: #fff #34495E;
} */
.unfold.active {
  width: 100%;
  height: 100%;
}
.unfold.active:before {
  border-width: 2900px 2900px 0 0;
  border-color: #fff #34495E;
}
/**********/
.btns-group {
  position: fixed;
  left: 0;
  bottom: 15px;
  padding-left: 50%;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
}
.home-btn {
  height: 56px;
  padding: 10px 60px;
  /* background-color: #5f7f72; */
  background-color: #1a1818;
  color: #fff;
  margin: 0 10px;
  border-radius: 56px;
  font-size: 17px;
  letter-spacing: 1px;
}
.home-btn:hover {
  color: #fff;
}
.nxtPrv-btn {
  width: 56px;
  height: 56px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nxtPrv-btn svg {
  width: 14px;
  height: auto;
  /* fill: #5f7f72; */
  fill: #1a1818;
}
.page1 {
  width: 100%;
  height: 700px;
  background-color: red;
}
.fade-in {
  animation: fade-in 2s ease-in-out;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}